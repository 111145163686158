jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    $("header").sticky({
        topSpacing: 0,
        zIndex: 999,
    });

    $("header").on("sticky-start", function () {
        $(".top-banner").slideUp();
        $(".offside").css({
            top: 60,
        });
        $(".offcanvas-overlay").css({
            top: 60,
        });
    });
    $("header").on("sticky-end", function () {
        $(".top-banner").slideDown();
        $(".offside").css({
            top: 80,
        });
        $(".offcanvas-overlay").css({
            top: 80,
        });
    });

    if ($(window).width() > 1024) {
        $(".dealer-result-wrap .map-wrapper").css({
            paddingLeft:
                parseInt(
                    $(".result-wrapper .container").css("marginLeft"),
                    10
                ) +
                15 +
                420 +
                "px",
        });

        $(window).resize(function () {
            $(".dealer-result-wrap .map-wrapper").css({
                paddingLeft:
                    parseInt(
                        $(".result-wrapper .container").css("marginLeft"),
                        10
                    ) +
                    15 +
                    420 +
                    "px",
            });
        });
    }

    $(".faq-item").on("click", function () {
        $(this).find(".faq-content ").slideToggle();
        $(this).toggleClass("active");
        $(this).siblings().find(".faq-content ").slideUp();
        $(this).siblings().removeClass("active");
    });

    $(".home-product-list").hide();

    $(".product-category-list a").on("click", function (e) {
        e.preventDefault();
        var index = $(this).attr("href");
        $(this).parent().siblings().find("a").removeClass("active");
        $(this).addClass("active");
        $(`.home-product-list${index}`).show();
        $(`.home-product-list${index}`).siblings(".home-product-list").hide();
        if ($(window).width() < 1024) {
            scrollToElement("#homePhasedProduct");
        }
    });

    if ($(window).width() > 1024) {
        $('.product-category-list a[href="#tab-1"]').trigger("click");
    }

    $(".share-link-toggle").on("click", function (e) {
        e.preventDefault();
        $(".share-link-options").toggleClass("show");
        $(this).toggleClass("close");
    });

    $(document).on("click", function (e) {
        if (
            $(".share-link-toggle").has(e.target).length === 0 &&
            $(".share-link-options").has(e.target).length === 0
        ) {
            $(".share-link-options").removeClass("show");
            $(".share-link-toggle").removeClass("close");
        }
    });

    if ($(window).width() < 576) {
        $(".footer-title").on("click", function () {
            $(".footer-title").not($(this)).next().slideUp();
            $(".footer-title").not($(this)).removeClass("active");
            $(this).next().slideToggle();
            $(this).toggleClass("active");
        });
    }
    $.fancybox.defaults.parentEl = "form";
    // $(".search-link").attr("href", "/SearchResults.aspx");
    newsSlider();
    productSlider();
    homeProductSlider();
    $.fancybox.open($(".event-popup"));
    eventCountdown();
    $(".count").countUp({
        time: 2000,
        delay: 10,
    });
    AOS.init({
        duration: 700,
        offset: 20,
    });
});

function parseDate(date) {
    var dateInit = date;
    var datearray = dateInit.split(" ");
    var dateNeedFormat = datearray[0];
    var dateNeedFormatArray = dateNeedFormat.split(/\//);
    var dateAfterFormat = [
        dateNeedFormatArray[1],
        dateNeedFormatArray[0],
        dateNeedFormatArray[2],
    ].join("/");
    return [dateAfterFormat, datearray[1]].join(" ");
}

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

var scrollToElement = function (el, ms) {
    var speed = ms ? ms : 2000;
    $("html,body").animate(
        {
            scrollTop: $(el).offset().top - $("header").height(),
        },
        speed
    );
};

const menuMapping = new MappingListener({
    selector: ".menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".logo-wrapper",
    desktopMethod: "insertAfter",
    breakpoint: 1025,
}).watch();

const homeBannerSlider = new Swiper(".home-banner-slider .swiper", {
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
    autoplay: {
        delay: 4000
    },
    loop: true,
    navigation: {
        prevEl: ".home-banner-slider .swiper-prev",
        nextEl: ".home-banner-slider .swiper-next",
    },
});

function newsSlider() {
    $(".news-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("news-slider-" + index);
        $this.find(".swiper-prev").addClass("news-slider-prev-" + index);
        $this.find(".swiper-next").addClass("news-slider-next-" + index);
        $this.find(".swiper-pagination").addClass("news-slider-pagination-" + index);

        var newsSlider = new Swiper(".news-slider-" + index + " .swiper", {
            slidesPerView: 1.5,
            spaceBetween: 15,
            navigation: {
                prevEl: ".news-slider-prev-" + index,
                nextEl: ".news-slider-next-" + index,
            },
            pagination: {
                el: ".news-slider-pagination-" + index,
                type: "bullets",
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.25,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        });
    });
}
function productSlider() {
    $(".product-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("product-slider-" + index);
        $this.find(".swiper-prev").addClass("product-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-slider-next-" + index);

        var newsSlider = new Swiper(".product-slider-" + index + " .swiper", {
            slidesPerView: 1.15,
            spaceBetween: 15,
            navigation: {
                prevEl: ".product-slider-prev-" + index,
                nextEl: ".product-slider-next-" + index,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.25,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        });
    });
}

const newProductSlider = new Swiper(".new-product-slider .swiper", {
    slidesPerView: 1.15,
    spaceBetween: 15,
    navigation: {
        prevEl: ".new-product-slider .swiper-prev",
        nextEl: ".new-product-slider .swiper-next",
    },
    breakpoints: {
        768: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1280: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
    },
});

const usefulLinkSlider = new Swiper(".useful-link-slider .swiper", {
    slidesPerView: 1.15,
    spaceBetween: 15,
    navigation: {
        prevEl: ".useful-link-slider .swiper-prev",
        nextEl: ".useful-link-slider .swiper-next",
    },
    breakpoints: {
        576: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1280: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});

const yearDotSlider = new Swiper(".year-dot-slider .swiper", {
    slidesPerView: 4,
    breakpoints: {
        576: {
            slidesPerView: 5,
        },
        768: {
            slidesPerView: 9,
        },
        1024: {
            slidesPerView: 10,
            spaceBetween: 0,
        },
    },
});

const yearContentSlider = new Swiper(".year-content-slider .swiper", {
    slidesPerView: 1,
    spaceBetween: 15,
    autoHeight: true,
    breakpoints: {
        1024: {
            autoHeight: false,
        },
    },
    thumbs: {
        swiper: yearDotSlider,
    },
    navigation: {
        prevEl: ".year-content-slider .swiper-prev",
        nextEl: ".year-content-slider .swiper-next",
    },
    on: {
        init: function (swiper) {
            $(".year-dot-slider-wrap img").attr(
                "src",
                $(
                    ".year-content-slider .swiper-slide-active .year-content"
                ).data("src")
            );
        },
        transitionStart: function (swiper) {
            $(".year-dot-slider-wrap img").attr(
                "src",
                $(
                    ".year-content-slider .swiper-slide-active .year-content"
                ).data("src")
            );
        },
    },
});

const appSlider = new Swiper(".app-slider .swiper", {
    slidesPerView: 1.15,
    spaceBetween: 10,
    breakpoints: {
        576: {
            slidesPerView: 1.5,
        },
        1024: {
            slidesPerView: 2,
        },
    },
    navigation: {
        prevEl: ".app-slider .swiper-prev",
        nextEl: ".app-slider .swiper-next",
    },
});

const awardSlider = new Swiper(".award-slider .swiper", {
    slidesPerView: 1.15,
    spaceBetween: 10,
    autoplay: {
        delay: 5000,
    },
    breakpoints: {
        576: {
            slidesPerView: 1.5,
        },
        768: {
            slidesPerView: 2.25,
        },
        1024: {
            slidesPerView: 3,
        },
    },
    navigation: {
        prevEl: ".award-slider .swiper-prev",
        nextEl: ".award-slider .swiper-next",
    },
});

const photoSlider = new Swiper(".photo-slider .swiper", {
    slidesPerView: 1.15,
    spaceBetween: 10,
    loop: true,
    slideToClickedSlide: true,
    breakpoints: {
        576: {
            slidesPerView: 1.5,
        },
        768: {
            slidesPerView: 2.25,
        },
        1024.98: {
            slidesPerView: 2.45,
            spaceBetween: -40,
            centeredSlides: true,
            initialSlide: 1,
        },
    },
    navigation: {
        prevEl: ".photo-slider .swiper-prev",
        nextEl: ".photo-slider .swiper-next",
    },
});

function homeProductSlider() {
    $(".home-product-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("home-product-slider-" + index);
        $this
            .find(".swiper-prev")
            .addClass("home-product-slider-prev-" + index);
        $this
            .find(".swiper-next")
            .addClass("home-product-slider-next-" + index);
        $this
            .find(".swiper-pagination")
            .addClass("home-product-slider-pagination-" + index);

        var homeProductListSlider = new Swiper(
            ".home-product-slider-" + index + " .swiper",
            {
                slidesPerView: 1.15,
                spaceBetween: 15,
                breakpoints: {
                    576: {
                        slidesPerView: 1.5,
                    },
                    768: {
                        slidesPerView: 2.25,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1280: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                },
                navigation: {
                    prevEl: ".home-product-slider-prev-" + index,
                    nextEl: ".home-product-slider-next-" + index,
                },
                pagination: {
                    el: ".home-product-slider-pagination-" + index,
                    type: "bullets",
                    clickable: true,
                },
            }
        );
    });
}

const homeFeaturedProductSlider = new Swiper(
    ".home-featured-product-slider .swiper",
    {
        slidesPerView: 1.15,
        spaceBetween: 15,
        breakpoints: {
            576: {
                slidesPerView: 1.5,
            },
            768: {
                slidesPerView: 2.25,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
        pagination: {
            el: ".home-featured-product-slider .swiper-pagination",
            type: "bullets",
            clickable: true,
        },
        navigation: {
            prevEl: ".home-featured-product-slider .swiper-prev",
            nextEl: ".home-featured-product-slider .swiper-next",
        },
    }
);

function eventCountdown() {
    $(".event-countdown").each(function () {
        var date = $(this).data("date");
        var formatDate = parseDate(date);
        $(this).countdown(formatDate, function (event) {
            $(this).html(
                event.strftime(
                    "<div class='count'><span class='number'>%-D</span><span class='unit'>Ngày</span></div>" +
                        "<div class='count'><span class='number'>%H</span><span class='unit'>Giờ</span></div>" +
                        "<div class='count'><span class='number'>%M</span><span class='unit'>Phút</span></div>" +
                        "<div class='count'><span class='number'>%S</span><span class='unit'>Giây</span></div>"
                )
            );
        });
    });
}
